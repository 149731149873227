button.rotate-right {
  background-color: transparent;
  border: none;
  line-height: 0;
  padding: 0;
  cursor: pointer;
}

button.rotate-right:disabled,
button.rotate-right[disabled] {
  cursor: default;
}

button.rotate-right:disabled img,
button.rotate-right[disabled] img {
  opacity: 0.4;
}
