div.spacer {
  width: 1em;
}

nav.toolbar {
  align-items: center;
  background-color: #f8f9fa;
  display: flex;
  gap: 2px;
  padding: 8px 16px;
}

nav.toolbar button {
  cursor: pointer;
}

nav.toolbar button.close:disabled,
nav.toolbar button.close[disabled] {
  cursor: default;
}

nav.toolbar button.close:disabled img,
nav.toolbar button.close[disabled] img {
  opacity: 0.4;
}
