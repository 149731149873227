nav.footer {
  font-size: 0.6em;
  padding: 0.5em;
}

nav.footer span {
  border-right: 1px solid gray;
  margin-right: 0.5em;
  padding-right: 0.5em;
}
