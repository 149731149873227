div.caption {
  display: flex;
  flex-direction: column;
  flex: auto;
}

div.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 1em;
  margin: 1em;
}

div.row {
  display: flex;
  flex-direction: row;
  gap: 0.5em;
}

div.workspace {
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  border-top: 1px solid black;
  display: flex;
  flex-basis: 50%;
}
