button.location {
    background: none;
    border: 0;
    cursor: pointer;

    &:disabled {
        cursor: default;

        img {
            opacity: 0.3;
        }
    }
}
