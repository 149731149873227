div.canvas-height {
  align-items: center;
  display: flex;
  gap: 0.65em;

  input {
    flex: auto;
    font-family: sans-serif;
    width: 6em;
  }
}
