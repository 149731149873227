div.caption-position {
  background-color: #efefef;
  border: 1px solid #cccccc;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  padding: 0.25rem;
}

div.caption-position button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

div.caption-position button:disabled {
  cursor: default;
}
