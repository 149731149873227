div.captions {
    border: 1px solid black;
    border-radius: 0.25rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;
    max-height: 4rem;
    overflow: auto;
    padding: 0.25rem;

    &:empty {
        display: none;
    }
}
