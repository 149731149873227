div.canvas {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  overflow: hidden;
}

div.canvas canvas {
  max-height: 100%;
  max-width: 100%;
}
