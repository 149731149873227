div.gallery {
  display: flex;
  flex-basis: 50%;
  flex-direction: column;
  flex-grow: 1;
}

ul {
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  display: flex;
  flex-basis: 50%;
  flex-grow: 1;
  flex-wrap: wrap;
  gap: 1em;
  justify-content: flex-start;
  list-style: none;
  margin: 0;
  overflow: auto;
  padding: 1em;
}

li {
  border: 1px solid transparent;
}
