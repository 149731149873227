div.canvas-width {
  align-items: center;
  display: flex;
  gap: 0.4em;
}

div.canvas-width input {
  flex: auto;
  font-family: sans-serif;
  width: 6em;
}
