div.sort {
    align-items: center;
    //background-color: lightgray;
    display: flex;
    gap: 0.25rem;
    justify-content: flex-end;
    padding: 0.5rem;

    select {
        border-radius: 0.25rem;
        padding: 0.25rem;
    }
}
