.login {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  header {
    flex-grow: 0;
  }

  main {
    align-items: center;
    display: flex;
    gap: 1em;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;

    a {
      &:visited {
        color: inherit;
      }
    }
  }
}
