button.open {
  background: none;
  border: 0;
  line-height: 0;
  padding: 0;
}

div.open {
  display: flex;
  position: relative;

  & div.callout {
    display: flex;
    flex-direction: column;
    left: -0.5rem;
    position: absolute;
    top: 32px;

    div.arrow {
      line-height: 0;

      img {
        flex-grow: 0;
        margin-left: 0.5rem;
      }
    }

    div.assistant {
      background-color: #0094ff;
      border-radius: 0.5rem;
      color: #fff;
      padding: 0.5rem;
      white-space: nowrap;
    }
  }
}

input.open {
  visibility: hidden;
  position: absolute;
}
