header {
  background-color: #112854;
  display: flex;
  justify-content: space-between;
  padding: 17px 16px 16px 16px;
}

h1 {
  color: white;
  font-size: 20px;
  font-weight: 400;
  margin: 0;
}
