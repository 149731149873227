a.save {
  height: 0;
  position: absolute;
  visibility: hidden;
  width: 0;
}

button.save {
  background-color: transparent;
  border: none;
  line-height: 0;
  padding: 0;
  cursor: pointer;
}

button.save:disabled,
button.save[disabled] {
  cursor: default;
}

button.save:disabled img,
button.save[disabled] img {
  opacity: 0.1;
}
