button.rotate-left {
  background-color: transparent;
  border: none;
  line-height: 0;
  padding: 0;
  cursor: pointer;
}

button.rotate-left:disabled,
button.rotate-left[disabled] {
  cursor: default;
}

button.rotate-left:disabled img,
button.rotate-left[disabled] img {
  opacity: 0.4;
}
