li.gallery-item {
  border: 4px outset transparent;
  display: flex;
  flex-grow: 0;
  height: 200px;
  position: relative;
  width: 200px;
}

li.selected {
  border: 4px outset red;
}

li button {
  background-color: black;
  border: 0;
  flex: auto;
  padding: 0;
  cursor: pointer;
}

li button:disabled,
li button[disabled] {
  cursor: default;
}

li.gallery-item button img {
  height: 200px;
  width: 200px;

  &:not([src]) {
    visibility: hidden;
  }
}

li img.status {
  position: absolute;
  right: 1px;
  top: 1px;
}
