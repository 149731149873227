button.next {
  background-color: transparent;
  border: none;
  line-height: 0;
  padding: 0;
  cursor: pointer;
}

button.next:disabled,
button.next[disabled] {
  cursor: default;
}

button.next:disabled img,
button.next[disabled] img {
  opacity: 0.1;
}
